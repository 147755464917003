import { AnimatePresence, Variants, motion as m } from "framer-motion";
import { useRef } from "react";

import { useUpdateTime } from "@/src/hooks";
import { CardInfo } from "../../shared/card-info";
import { HeroBackground, HeroHeader, HeroImage } from "./components";
import { useLandingBannerGetSuspense as useLandingBannerGet } from "architecture/src/shared/api/endpoints/landing/landing";
import { Typography } from "@/components/ui/data-display/typography";

const variants: Variants = {
  initial: {
    opacity: 0,
    scale: 0.95,
    x: "50%",
    rotateY: 75,
    transformOrigin: "left center",
  },
  animate: {
    opacity: 1,
    scale: 1,
    x: "0%",
    rotateY: 0,
    transition: {
      type: "spring",
      stiffness: 80,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    x: "-50%",
    rotateY: -75,
    transformOrigin: "right center",
  },
};

export const Hero = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { data: bannerContent } = useLandingBannerGet();
  const active = useUpdateTime({
    length: bannerContent.info.length,
  });
  return (
    <section className="container relative grid grid-cols-4 gap-4 pt-8 -md:min-h-screen md:items-center md:gap-10 md:grid-cols-12">
      <Typography component="h1" className="sr-only">
        Transformando Desafíos en Oportunidades: Decisiones en Asuntos Públicos
        con Impacto.
      </Typography>
      <div
        ref={ref}
        className="absolute inset-0 h-screen opacity-0 pointer-events-none"
      />
      {/* Background */}
      {/*       <div className="self-start col-span-2 col-start-2 row-start-1 md:col-span-5 md:col-start-3 -z-10">
        <Hero.Background />
      </div> */}

      {/* Text */}
      <div className="col-span-3 col-start-1 row-start-1 md:col-start-1 md:gap-10 md:col-span-5">
        <Hero.Header
          title={bannerContent.title}
          summary={bannerContent.summary}
        />
      </div>

      {/* Image */}

      <div className="col-span-2 col-start-3 row-start-1 md:col-start-7 md:col-span-6 mt-72 md:mt-0 relative">
        <div className="rounded-dot absolute top-6 md:-top-16 left-0 md:-left-[12%] w-full md:w-[125%] h-full md:h-[120%] z-10 bg-white" />
        <Hero.Image image={bannerContent.image} title={bannerContent.title} />
      </div>

      {/* Cards */}
      <m.div
        className="z-20 inline-flex self-end justify-center col-span-4 col-start-1 row-start-1 md:mb-0 mb-7 md:col-start-6 md:self-center perspective"
        layout
      >
        <AnimatePresence mode="popLayout" initial={false}>
          {bannerContent.info[active] && (
            <m.div
              key={`${bannerContent.info[active].title}-${active}`}
              variants={variants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <CardInfo {...bannerContent.info[active]} />
            </m.div>
          )}
        </AnimatePresence>
      </m.div>
    </section>
  );
};

Hero.Header = HeroHeader;
Hero.Background = HeroBackground;
Hero.Image = HeroImage;
