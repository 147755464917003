import React, { useState, useEffect } from "react";
import { WGSDesktop } from "./wgs-desktop";
import { WGSMobile } from "./wgs-mobile";

const WGS = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Si el ancho de la ventana es menor o igual a 768px, es móvil
      setIsMobile(window.innerWidth <= 768);
    };

    // Llamamos a handleResize para establecer el valor inicial
    handleResize();

    // Escuchar los cambios de tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Limpiar el evento al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      {isMobile ? <WGSMobile /> : <WGSDesktop />}
    </div>
  );
};

export default WGS;
